<template>
  <DetailItemEditCard item-name="teams" :promises.sync="detailItemEditCardPromises" title="Nieuw team aanmaken"
    :detailViewRouteLocation="{ name: RouteNames.TEAM_OVERVIEW }" confirmationMessage="Team werdt succesvol aangemaakt"
    update-store-action="teamsModule/createTeam" :update-store-action-payload="newTeam"
    :success-callback="teamCreatedSuccessCallback">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="newTeam.teamName" persistent-placeholder label="Team naam" type="text"
            :rules="getRequiredRules('Team naam moet ingevuld worden')" required autofocus />
        </v-col>
      </v-row>
    </template>
  </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue";
import RouteNames from "@/router/RouteNames";
import { getRequiredRules } from '@/shared/utils/inputRulesUtils.js';

export default {
  name: "TeamCreate",
  components: {
    DetailItemEditCard,
  },
  data() {
    return {
      RouteNames,
      newTeam: {
        teamName: null,
      },
      detailItemEditCardPromises: [],
    }
  },
  methods: {
    getRequiredRules,
    teamCreatedSuccessCallback(data) {
      this.$router.push({ name: RouteNames.TEAM_DETAIL, params: { teamId: data.id } })
    }
  }
}
</script>